var config = {
    apiKey: "AIzaSyDME2L9g-QGeTY1exn1qsm5toeu6ATaBRw",
    authDomain: "waultimate-81379.firebaseapp.com",
    databaseURL: "https://waultimate-81379.firebaseio.com",
    projectId: "waultimate-81379",
    storageBucket: "waultimate-81379.appspot.com",
    messagingSenderId: "633010889169"
};

export default config;


